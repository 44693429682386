import './video.css';
import Youtube from '../assets/images/youtubeThumb.png';

export const StoryCard = ({ collection }) => {
    const url = collection["video_url"];
    const title = collection["title"];
    const thumbnail = collection["thumbnail"];
    return (
        <a href={url} target="_blank" rel="noreferrer" className='video-card-wrapper'>
            <div className='video-card-image-wrapper'>
            <img src={thumbnail} alt="" />
            <img src={Youtube} alt="" />
            </div>
            <div className='video-card-content-wrapper'>
                <h4>{title}</h4>
            </div>
        </a>
    )
}