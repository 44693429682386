import React, { useEffect, useRef, useState } from 'react';
import { Header } from "../header";
import { ArticleContent } from "./content";
import { RecentArticles } from "../recent-articles";
import { ConsultationLink } from "../consultation";
import { getArticle } from "../reducers/actions/articleAction";
import { getRecents } from "../reducers/actions/recentsAction";
import { connect } from "react-redux"
import { Footer } from "../footer";
import ScrollToTopButton from "../scroll-top";
import './article.css';
import { postPingVikatan, pingSchema } from '../ping';

const ArticlePage = (props) => {
    const increment = useRef(null);
    const [opacity, setOpacity] = useState(2);
    const [display, setDisplay] = useState("block");
    const [data, setData] = useState(false);
    const [pingPosted, setPingPosted] = useState(false);
    const [newPageTitle, setNewPageTitle] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await props.getArticle();
                await props.getRecents();

                // Get the pageTitle from the article data
                const fetchedPageTitle = props.article?.article?.['article-info']?.[0]?.['article_title'];

                if (fetchedPageTitle) {
                    setNewPageTitle(fetchedPageTitle);
                    document.title = fetchedPageTitle;
                }

                setData(true);
                increment.current = setInterval(() => {
                    setOpacity((oldCount) => oldCount === 0 ? 0 : oldCount.toFixed(1) - 0.1)
                }, 100);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        return () => {
            clearInterval(increment.current);

            // Reset the document title to the default value when the component unmounts
            document.title = "Kauvery Hospital";
        };

    }, [props, pingPosted]);

    useEffect(() => {
        // This useEffect triggers when newPageTitle changes
        if (newPageTitle && !pingPosted) {
            // Post ping data with the updated pageTitle
            postPingVikatan(pingSchema("PageView"));
            setPingPosted(true); // Set the flag to prevent further pings
        }
    }, [newPageTitle, pingPosted]);

    useEffect(() => {
        if (opacity === 0) {
            clearInterval(increment.current);
            setTimeout(
                () => setDisplay("none"),
                1000
            );
        }
    }, [opacity]);

    if (opacity === 0) {
        clearInterval(increment.current);
    }

    return (
        <div className="base">
            <div id="preloader" style={{ opacity: opacity, display: display }}>
                <div className='inner'>
                    <div className='page-loader'>
                        <span className='loader'></span>
                    </div>
                </div>
            </div>

            {data && (
                <div>
                    <Header />
                    <ArticleContent data={props.article.article} />
                    <RecentArticles data={props.recents.recents} />
                    <ConsultationLink />
                    <div className="mobile">
                        <ScrollToTopButton />
                    </div>
                    <Footer />
                </div>
            )}
        </div>
    )
}

const mapStatetoProps = state => {
    return {
        article: state.article,
        recents: state.recents
    }
}

const dispatchToprops = dispatch => {
    return {
        getArticle: () => dispatch(getArticle()),
        getRecents: () => dispatch(getRecents())
    }
}

export default connect(mapStatetoProps, dispatchToprops)(ArticlePage);
