import React, { useState, useEffect } from 'react';
import Arrow from '../assets/images/arrow.png'
import './index.css';

const ScrollToTopButton = () => {

  const scrollToTop = () => {
    // Scroll to top with smooth animation
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`scroll-to-top-button `}
      style={{ backgroundColor: '#e02c6d' }}
      onClick={scrollToTop}
    >
      <img src={Arrow} alt='arrow' />
    </div>
  );
};

export default ScrollToTopButton;
