import React from "react";
import { StoryCard } from "./story-card";
import "./recent.css";

export const RecentArticles = ({data}) => {

    return (
        <div className="recent-wrapper container-wrapper">
            <h1>சமீபத்திய கட்டுரைகள்</h1>
            <div className="recent-article-list">
                {data["latest-article"] && data["latest-article"].slice(0, 4).map(item => <StoryCard key={item["article_id"]} collection={item} />)
                }
            </div>
        </div>
    );
}