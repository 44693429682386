
import {GET_ORGANS} from '../types'

const initialState = {
    organs:[],
    loading:true
}

const organsReducer = (state = initialState, action) => {
    
    switch(action.type){
        case GET_ORGANS:
        return {
            ...state,
            organs:action.payload,
            loading:false

        }
        default: return state
    }

}

export default organsReducer;