import './recent.css';

export const StoryCard = ({collection}) => {
    const cardImage = collection["article_img"];
    const cardContent = collection["article_title"];
    const slug = collection["article_slug"];
    return(
        <a href={`/${slug}`} className='recent-card-wrapper'>
            <div className='recent-card-image-wrapper'>
            <img src={cardImage} alt="" />
            </div>
            <div className='recent-card-content-wrapper'>
                <h4>{cardContent}</h4>
            </div>
        </a>
    )
}