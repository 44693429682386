import './header.css'
import React from 'react';
import HeaderText from '../header_text.png';
import HeaderTextMobile from '../header_text_mobile.png';
import Facebook from '../assets/images/facebook.png';
import Instagram from '../assets/images/instagram.png';
import Youtube from '../assets/images/youtube.png';
import LinkedIn from '../assets/images/linkedin.png';
import UtchiMudhal from '../assets/images/utchimudhal.png';
import BrandLogo from '../assets/images/brandLogo.png';
import { Link } from 'react-router-dom';

export const Header = () => {

    const [searchList, setList] = React.useState();
    const [headerText, setHeaderText] = React.useState();

    React.useEffect(() => {
        function myFunction(x) {
            if (x.matches) {
                setHeaderText(HeaderTextMobile);
            } else {
                setHeaderText(HeaderText);
            }
        }

        var x = window.matchMedia("(max-width: 700px)")
        myFunction(x)
    }, []);

    function searchFunction() {
        var element = document.getElementById('search-input');
        var length = element.value.length;
        if (length >= 3) {
            fetch(`https://special.vikatan.com/kauvery/index.php?module=articles&type=search&q=${element.value}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data["article-info"]) {
                        setList(data)
                    } else setList(["no articles found"])
                })
        } else {
            setList();
        }
    }


    return (
        <div className='header-wrapper'>
            <header>
                <div className="header-text">
                    <img src={headerText} alt="" />
                </div>
                <div className="mid-grid">
                    <Link to="/" className="home-icon">
                        <img className="mid-grid-image" src={UtchiMudhal} alt="" />
                    </Link>
                </div>
                <div className="brand-logo">
                    <img className="third-grid-image" src={BrandLogo} alt="" />
                    <div className="icon-wrapper">
                        <a href="https://www.facebook.com/kauveryhospitalchennai/" target="_blank" rel="noreferrer">
                            <img src={Facebook} alt="" />
                        </a>
                        <a href="https://www.instagram.com/kauveryhospital/" target="_blank" rel="noreferrer">
                            <img src={Instagram} alt="" />
                        </a>
                        <a href="https://www.youtube.com/c/KauveryHospital/" target="_blank" rel="noreferrer">
                            <img src={Youtube} alt="" />
                        </a>
                        <a href="https://www.linkedin.com/company/kauveryhospitalgroup/" target="_blank" rel="noreferrer">
                            <img src={LinkedIn} alt="" />
                        </a>
                    </div>
                </div>
                {/* <div className={`search-wrapper ${searchList ? "" : "default-search-wrapper"}`}>
                    <input type="search" id="search-input" placeholder="Search" onKeyUp={searchFunction}></input>
                    <ul className="search-result">
                        {
                            searchList && searchList["article-info"] ? searchList["article-info"].map(item => (
                                <li><a href={`/${item.slug}`}>{item.title}</a></li>
                            )) : searchList && (<li>{searchList}</li>)
                        }
                    </ul>
                </div> */}
            </header>
        </div>
    );
}