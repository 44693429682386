export const GET_ORGANS = 'GET_ORGANS';
export const ORGANS_ERROR = 'ORGANS_ERROR';
export const GET_VIDEOS = 'GET_VIDEOS';
export const VIDEOS_ERROR = 'VIDEOS_ERROR';
export const GET_RECENTS = 'GET_RECENTS';
export const RECENTS_ERROR = 'RECENTS_ERROR';
export const GET_QUIZ = 'GET_QUIZ';
export const QUIZ_ERROR = 'QUIZ_ERROR';
export const GET_ARTICLE = 'GET_ARTICLE';
export const ARTICLE_ERROR = 'ARTICLE_ERROR';
