import "./faq.css";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QuizPNG from "../assets/images/Quiz.png"
import ScrollToTopButton from "../scroll-top";
import Prev from '../assets/images/prev.png';

export const FAQ = ({ data }) => {
    const [showPopup, setShowPopup] = React.useState(false);
    var [score, setScore] = React.useState(0);
    const [showScore, setShowScore] = React.useState(false);
    const [frame, setFrame] = React.useState(0);
    const [isDragging, setIsDragging] = React.useState(false);

    const handleCardClick = (index) => {
        if (!isDragging) {
            setFrame(index);
        }
    };

    const handleSliderDragStart = () => {
        setIsDragging(true);
    };

    const handleSliderDragEnd = () => {
        setIsDragging(false);
    };

    const cards = [
        {
            thumbnail: 'https://images.assettype.com/vikatan/2023-07/4857510a-fb3d-4e66-aa60-acf7fff9bca0/0.jpg',
            videoUrl: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Favalvikatan%2Fvideos%2F433744312194199%2F&show_text=false&width=560&t=0'
        },
        {
            thumbnail: 'https://images.assettype.com/vikatan/2023-07/a3647a30-b6e7-479a-8cb6-222752e47911/0.jpg',
            videoUrl: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Favalvikatan%2Fvideos%2F886810275822858%2F&show_text=false&width=560&t=0'
        },
        {
            thumbnail: 'https://images.assettype.com/vikatan/2023-06/8374dfcf-4797-46b5-a14e-1ad0abd42409/unnamed.jpg',
            videoUrl: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Favalvikatan%2Fvideos%2F1161950384696063%2F&show_text=false&width=560&t=0'
        },
        {
            thumbnail: 'https://images.assettype.com/vikatan/2023-07/5b2f02c0-18dc-4919-8777-fded821341b2/0.jpg',
            videoUrl: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Favalvikatan%2Fvideos%2F1159351234970997%2F&show_text=false&width=560&t=0'
        },
        {
            thumbnail: 'https://images.assettype.com/vikatan/2023-06/d5b5fa01-c7b7-4f44-a5fd-4153bc657b2b/unnamed.jpg',
            videoUrl: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Favalvikatan%2Fvideos%2F995463561433893%2F&show_text=false&width=560&t=0'
        },
        {
            thumbnail: 'https://images.assettype.com/vikatan/2023-06/8042e1de-da3e-4e97-82f4-9893d78ed7ed/unnamed.jpg',
            videoUrl: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Favalvikatan%2Fvideos%2F1380683596097894%2F&show_text=false&width=560&t=0'
        }
    ];
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settingsWebinar = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 3.5,
        beforeChange: handleSliderDragStart,
        afterChange: handleSliderDragEnd,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3.5
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 2.4
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1.5
                }
            }
        ]
    };

    const slides = [];

    const validateSelect = (e) => {
        if (e.target.parentElement.parentElement.className === "item" || e.target.tagName === "SPAN" || e.target.tagName === "LABEL") return;
        var option = e.target.parentElement.parentElement.id;
        var child = e.target.parentElement.parentElement.parentElement.children;
        if (option === "1") {
            e.target.setAttribute("class", "success");
            setScore(score + 1);
        } else {
            e.target.setAttribute("class", "error");
            for (let i = 0; i < child.length; i++) {
                if (child[i].id === "1") {
                    child[i].children[0].children[0].setAttribute("class", "success");
                    child[i].children[0].children[1].setAttribute("class", "success");
                    child[i].children[0].children[1].checked = true;
                }
            }
        }
        for (let i = 0; i < child.length; i++) {
            child[i].setAttribute("class", "quiz_options disable");
        }
    }

    for (let x in data) {
        slides.push(
            <div className="slide-item">
                <div className="item">
                    <h1><span className="count"></span><span>{data[x]["question"]}</span></h1>
                    <ul className="options-list">
                        {
                            data[x]["options"].map((option, i) => (
                                <li key={i} className="quiz_options" id={option["correct_answer"]} onClick={(e) => validateSelect(e)}><span className="option-span"><label for={option["option_id"]}>{option["option"]}</label><input type="checkbox" id={option["option_id"]} name="numbers" /></span></li>
                            ))
                        }
                    </ul>
                </div>
                <div className="cta-wrapper"> {x === Object.keys(data).length.toString() && <p onClick={() => { setShowScore(true) }} className="cta-btn">submit</p>}</div>
            </div>
        )
    }

    return (
        <div className="faq-base container-wrapper">
            <h1>சிறப்பு வெபினார்கள்</h1>
            <div className="faq-wrapper">
                <Slider {...settingsWebinar}>
                    {cards.map((card, index) => (
                        <div className="video-grid" key={index}>
                            <div className="video-box">
                                <div onClick={() => handleCardClick(index + 1)}>
                                    <div className="thumbnail">
                                        <img src={card.thumbnail} alt="thumbnail" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="fixed-elements">
                {/* <div className="quiz-btn-wrapper"><div className="quiz-btn" onClick={() => { setShowPopup(!showPopup) }}>Ask your health-related questions here…</div></div> */}
                <ScrollToTopButton />
            </div>
            {/* <div className="quiz-btn-wrapper-desktop"><div className="quiz-btn" onClick={() => { setShowPopup(!showPopup) }}>Ask your health-related questions here…</div></div> */}
            {showPopup && <div id="myModal" className="modal">
                <div className="modal-content">
                    <span className="close" onClick={() => { setShowPopup(!showPopup) }}>&times;</span>
                    {showScore ? (
                        <div className="score-page">
                            <div>
                                <img src={QuizPNG} alt="" />
                            </div>
                            <div className="score-text">
                                <h3>CONGRATULATION</h3>
                                <h3 className="text-orange">YOUR HEALTH</h3>
                                <h3>SCORE IS</h3>
                                <h3 className="text-orange">{score}/{Object.keys(data).length}</h3>
                            </div>
                        </div>
                    ) : (
                        <Slider {...settings}>
                            {slides}
                        </Slider>
                    )}
                </div>
            </div>}
            {cards.map((card, index) => (
                <div className="iframe-wrapper" style={{ display: frame === 0 ? 'none' : frame === index + 1 ? 'block' : 'none' }} key={index}>
                    <div className="close-div" onClick={() => setFrame(0)}><img src={Prev} alt='arrow' /></div>
                    <div className="video-iframe">
                        <iframe src={card.videoUrl} width="560" height="314" title="iframe" scrolling="no" frameBorder="0" allowFullScreen="true"></iframe>
                    </div>
                </div>
            ))}
        </div>
    );
}