import React from 'react';
import { Header } from './header';
import { Organs } from './organs';
import { VideoSection } from './video-section';
import { FAQ } from './FAQ';
import { ConsultationLink } from './consultation';
import { getOrgans } from './reducers/actions/organsAction';
import { getVideos } from './reducers/actions/videosAction';
import { getRecents } from './reducers/actions/recentsAction';
import { getQuiz } from './reducers/actions/quizAction';
import { connect } from "react-redux"
import { Footer } from './footer';
import ScrollToTopButton from './scroll-top';
import { postPingVikatan, pingSchema } from './ping';
import './App.css';

function App(props) {
  const increment = React.useRef(null);
  const [data, setData] = React.useState(false);
  const [opacity, setOpacity] = React.useState(2);
  const [display, setDisplay] = React.useState("block");

  console.log("kauvery-build-test------------------");

  React.useEffect(() => {
    props.getOrgans();
    props.getVideos();
    props.getRecents();
    props.getQuiz();
    postPingVikatan(pingSchema("PageView"));
  }, []);

  const { organs, videos, recents, quiz } = props;

  React.useEffect(() => {
    if (organs.loading === false && videos.loading === false && recents.loading === false && quiz.loading === false) {
      setData(true);
      increment.current = setInterval(() => {
        setOpacity((oldCount) => oldCount === 0 ? 0 : oldCount.toFixed(1) - 0.1)
      }, 100)
    }

    return () => {
      clearInterval(increment);
    };

  }, [organs, videos, recents, quiz]);


  React.useEffect(() => {
    if (opacity === 0) {
      clearInterval(increment);
      setTimeout(
        () => setDisplay("none"),
        1000
      );
    }
  }, [opacity]);

  if (opacity === 0) {
    clearInterval(increment);
  }

  return (
    <div className="base">

      <div id="preloader" style={{ opacity: opacity, display: display }}>
        <div className='inner'>
          <div className='page-loader'>
            <span className='loader'></span>
          </div>
        </div>
      </div>


      {data &&
        <div><Header />
          <Organs data={organs.organs} recents={recents.recents} />
          <VideoSection data={videos.videos} />
          <FAQ data={quiz.quiz} />
          <ConsultationLink />
          <div className='desktop'>
            <ScrollToTopButton />
          </div>
          <Footer />
        </div>
      }
    </div>
  );
}

const mapStatetoProps = state => {
  return {
    organs: state.organs,
    videos: state.videos,
    recents: state.recents,
    quiz: state.quiz
  }
}

const dispatchToprops = dispatch => {
  return {
    getOrgans: () => dispatch(getOrgans()),
    getVideos: () => dispatch(getVideos()),
    getRecents: () => dispatch(getRecents()),
    getQuiz: () => dispatch(getQuiz())
  }
}

export default connect(mapStatetoProps, dispatchToprops)(App); 
