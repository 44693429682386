import { combineReducers } from 'redux'
import organsReducer from './organs'
import videosReducer from "./videos"
import recentsReducer from './recents'
import quizReducer from './quiz'
import articleReducer from './article'

export default combineReducers({
  organs: organsReducer,
  videos: videosReducer,
  recents: recentsReducer,
  quiz: quizReducer,
  article: articleReducer
})