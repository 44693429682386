
import { VIDEOS_ERROR, GET_VIDEOS } from '../../types'
import axios from 'axios'

export const getVideos = () => async dispatch => {
    axios.get('https://special.vikatan.com/kauvery/index.php?module=videos')
    .then(function (response) {
        dispatch( {
            type: GET_VIDEOS,
            payload: response.data
         })
    })
    .catch(function (error) {
        dispatch( {
            type: VIDEOS_ERROR,
            payload: error
         })
    })

}