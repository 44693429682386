
import {GET_VIDEOS} from '../types'

const initialState = {
    videos:[],
    loading:true
}

const videosReducer = (state = initialState, action) => {
    
    switch(action.type){
        case GET_VIDEOS:
        return {
            ...state,
            videos:action.payload,
            loading:false

        }
        default: return state
    }

}

export default videosReducer;