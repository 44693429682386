import {GET_QUIZ} from '../types'

const initialState = {
    quiz:[],
    loading:true
}

const quizReducer = (state = initialState, action) => {
    
    switch(action.type){
        case GET_QUIZ:
        return {
            ...state,
            quiz:action.payload,
            loading:false

        }
        default: return state
    }

}

export default quizReducer;