import { GET_RECENTS, RECENTS_ERROR } from '../../types'
import axios from 'axios'

export const getRecents = () => async dispatch => {
    axios.get('https://special.vikatan.com/kauvery/index.php?module=articles&type=latest-articles')
    .then(function (response) {
        dispatch( {
            type: GET_RECENTS,
            payload: response.data
         })
    })
    .catch(function (error) {
        dispatch( {
            type: RECENTS_ERROR,
            payload: error
         })
    })

}
