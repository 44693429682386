
import { ARTICLE_ERROR, GET_ARTICLE } from '../../types'
import axios from 'axios'

export const getArticle = () => async dispatch => {
    axios.get(`https://special.vikatan.com/kauvery/index.php?module=articles&type=article-info&article_slug=${window.location.pathname.split('/')[1]}`)
    .then(function (response) {
        dispatch( {
            type: GET_ARTICLE,
            payload: response.data
         })
    })
    .catch(function (error) {
        dispatch( {
            type: ARTICLE_ERROR,
            payload: error
         })
    })

}