import './organ.css';

export const StoryCard = ({ collection }) => {
    const cardImage = collection["article_img"];
    const cardContent = collection["article_title"];
    const slug = collection["article_slug"];
    return (
        <a href={`/${slug}`} className='card-wrapper'>
            <div className='card-image-wrapper'>
                <img src={cardImage} alt="" />
            </div>
            <div className='card-content-wrapper'>
                <h4>{cardContent}</h4>
            </div>
        </a>

    )
}