import React from "react";
import "./article.css";
import { Link } from "react-router-dom";

export const ArticleContent = ({ data }) => {

    return (
        <div className="article-base">
            {
                data && data["article-info"] &&
                <div className="content-wrapper">
                    <p><Link to="/" className="home-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29.836" height="23.201" viewBox="0 0 29.836 23.201">
                            <path data-name="home"
                                d="m14.521 8.272-9.549 7.865v8.488a.829.829 0 0 0 .829.829l5.8-.015a.829.829 0 0 0 .825-.829v-4.956a.829.829 0 0 1 .829-.829h3.315a.829.829 0 0 1 .829.829v4.954a.829.829 0 0 0 .829.831l5.8.016a.829.829 0 0 0 .829-.829v-8.494l-9.543-7.86a.631.631 0 0 0-.793 0zm15.084 5.346-4.33-3.569V2.875a.622.622 0 0 0-.622-.622h-2.9a.622.622 0 0 0-.622.622v3.761l-4.637-3.815a2.486 2.486 0 0 0-3.159 0L.225 13.618a.622.622 0 0 0-.083.875l1.32 1.607a.622.622 0 0 0 .876.084L14.521 6.149a.631.631 0 0 1 .792 0L27.5 16.183a.622.622 0 0 0 .875-.083l1.321-1.606a.622.622 0 0 0-.088-.877z"
                                transform="translate(.001 -2.254)" style={{ fill: "#000" }} />
                        </svg>  Home
                    </Link></p>
                    <h3>{data["article-info"][0]["article_title"]}</h3>
                    <div className="article-header-img"><img src={data["article-info"][0]["article_img"]} alt="" /></div>
                    <div>
                        <article
                            className="post-inner post post-7760 type-post status-publish format-standard has-post-thumbnail hentry category-health-beauty tag-peanut-butters"
                            dangerouslySetInnerHTML={{ __html: data["article-info"][0]["article_desc"] }}
                        />
                        { }
                    </div>
                </div>
            }
        </div>
    );
}

export default ArticleContent;
