import React from "react";
import Slider from "react-slick";
import { StoryCard } from "./story-card";
import './video.css';

export const VideoSection = ({ data }) => {
  const slides = [];

  for (let x in data["videos"]) {
    slides.push(<StoryCard collection={data["videos"][x]} />)
  }


  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 3.5,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3.5
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2.4
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5
        }
      }
    ]
  };
  return (
    <div className="slider-wrapper">
      <div className="container-wrapper">
        <h1>சமீபத்திய வீடியோக்கள்</h1>
        <Slider {...settings}>{slides}</Slider>
      </div>
    </div>
  );
}