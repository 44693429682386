import React from 'react';
import ReactDOM from 'react-dom/client';
import thunkMiddleware from "redux-thunk";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { createStore, applyMiddleware } from "redux"
import { Provider } from "react-redux";
import reducer from './reducers';
// import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import ArticlePage from './routes';
import reportWebVitals from './reportWebVitals';

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<ArticlePage />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();