
import { ORGANS_ERROR, GET_ORGANS } from '../../types';
import axios from 'axios'

export const getOrgans = () => async dispatch => {
    axios.get('https://special.vikatan.com/kauvery/index.php?module=organs')
    .then(function (response) {
        dispatch( {
            type: GET_ORGANS,
            payload: response.data
         })
    })
    .catch(function (error) {
        dispatch( {
            type: ORGANS_ERROR,
            payload: error
         })
    })

}