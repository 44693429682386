import axios from 'axios';
import Cookie from "universal-cookie";
import { v4 as uuidv4 } from 'uuid';
import wretch from 'wretch';
import cookie from "cookie";
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const universalCookie = new Cookie();

export const pingSchema = async (eventName) => {
  let ip;
  let city;
  let country;

  try {
    const jsonResponse = await fetchLocation();
    ip = jsonResponse["ip"];
    city = jsonResponse["city"];
    country = jsonResponse["Country"];
  } catch (error) {
    console.error("An error occurred:", error);
  }

  const data =
  {
      user: {
          cookie: getCookie("umpv-thinmint"),
          customer: {
              userId: "",
              username: "",
              userType: "",
              userEmail: "",
              userJoinDate: ""
          }
      },
      event: {
          type: eventName,
          timestamp: Date.now(),
          eventattribute: {
              pageTitle: document.title,
              path: window.location.pathname,
              domain: window.location.hostname
          }
      },
      device: {
          devicetype: getDeviceType(),
          CookieID: getCookie("umpv-thinmint"),
          Platform: getOS(),
          ip: ip || "",
          city: city || "",
          country: country || "",
          userAgent: navigator.userAgent,
          SessionID: getSessionID("umpv-sessionid") || "",
          GACuid: "",
          DeepCookie: "",
          ampthinmint: getCookie("umpv-amp-thinmint") || ""
      }
  };

  return data;
}

export const postPingVikatan = (dataPromise) => {

  dataPromise
    .then(data => {
      axios.post('https://ping.vikatan.com/umpv', { data }, {
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
        }
      })
        .then(response => {
          // Handle the response here
          return response.data;
        })
        .catch(function (error) {
          console.log("Error:", error);
        });
    })
    .catch(error => {
      console.error("An error occurred while getting data from pingSchema:", error);
    });
}

export const postPingVikatanEvents = (dataPromise) => {
  dataPromise
    .then(data => {
      axios.post('https://ping.vikatan.com/events', { data }, {
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
        }
      })
        .then(response => {
          // Handle the response here
          console.log("Response from 'https://ping.vikatan.com/umpv':", response.data);
        })
        .catch(function (error) {
          console.log("Error:", error);
        });
    })
    .catch(error => {
      console.error("An error occurred while getting data from pingSchema:", error);
    });
}

const getCookie = (key) => {
  const vikatanThinmint = universalCookie.get(key);

  if (!vikatanThinmint) {
    const expireDate = 3600 * 24 * 3600 * 1000;
    universalCookie.set(key, uuidv4(), {
      expires: new Date(Date.now() + expireDate),
      path: window.location.pathname,
      domain: ".utchimudhalpadhamvarai.com"
    });
  }
  return universalCookie.get(key);
}

const getSessionID = (key) => {

  if (!universalCookie.get(key)) {
    const now = new Date();
    const minutes = 30;
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const uniq = Math.random()
      .toString(16)
      .slice(2);
    const uniqDate = new Date().getTime();
    const uniqId = `${uniqDate}i${uniq}`;
    const expireDate = 3600 * 24 * 3600 * 1000;
    universalCookie.set(key, uniqId, {
      expires: new Date(Date.now() + expireDate),
      path: window.location.pathname,
     domain: ".utchimudhalpadhamvarai.com"
    });
  }
  return universalCookie.get(key);
}

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

const getOS = () => {
  var Name = "unknown";
  if (navigator.userAgent.indexOf("Win") !== -1) Name = "windows";
  if (navigator.userAgent.indexOf("Mac") !== -1) Name = "mac";
  if (navigator.userAgent.indexOf("Linux") !== -1) Name = "linux";
  if (navigator.userAgent.indexOf("Android") !== -1) Name = "android";
  if (navigator.userAgent.indexOf("like Mac") !== -1) Name = "ios";
  return Name;
};

function fetchLocation() {
  let cookies = cookie.parse(document.cookie);
  let evLocation = cookies["ev-location"];
  if (!evLocation) {
    return wretch(`https://ping.vikatan.com/getip`)
      .headers({ "x-auth-key": "wadBSGthR5XrQ9hR" })
      .get()
      .json(response => {
        const res = {};
        res.ping = {
          data: response
        };

        universalCookie.set("ev-location", res, { path: "/", domain: ".utchimudhalpadhamvarai.com" });
        return res.ping.data;
      })
      .catch(error => error);
  } else {
    const res = JSON.parse(evLocation);
    return res.ping.data;
  }
}