import "./App.css";
import ChatIcon from "./assets/images/Chat.png";
import { postPingVikatanEvents, pingSchema } from './ping';

export const ConsultationLink = () => {
    return (
        <a className="consultaion-div" href="https://www.kauveryhospital.com/doctors" target="_blank" rel="noreferrer" onClick={() => {
            if ("gtag" in window) {
                window.gtag('event', 'clicked', {
                    'event_category': 'consultation-link-click',
                    'event_label': window.location.href
                });
            }
            postPingVikatanEvents(pingSchema("ConsultationLinkClick"));
        }}>
            <span className="consultation-content">Do you wish to consult with the doctor</span>
            <span className="consultation-img"><img src={ChatIcon} alt="" /></span>
        </a>
    )
}