import React from 'react';
import './organ.css'
import { StoryCard } from './story-card';
import { RecentStoryCard } from './recent-story-card';
import Slider from 'react-slick';
import Prev from '../assets/images/prev.png';
import Next from '../assets/images/next.png';

export const Organs = ({ data, recents }) => {
    const [organ, setOrgan] = React.useState("1");
    const [hoverOrgan, setHoverOrgan] = React.useState("0")

    React.useEffect(() => {
        setOrgan(data["list"][0]["organ_id"])
    }, []);

    const CustomPrevArrow = (props) => (
        <img src={Prev} alt='arrow' className="custom-prev-arrow" onClick={props.onClick} />
    );

    const CustomNextArrow = (props) => (
        <img src={Next} alt='arrow' className="custom-next-arrow" onClick={props.onClick} />
    );

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var slides = data && data["list"] && data["list"].map((singleOrgan, i) => (
        <div key={i} onClick={() => { setOrgan(singleOrgan["organ_id"]) }} onMouseOver={() => { setHoverOrgan(singleOrgan["organ_id"]) }} onMouseOut={() => { setHoverOrgan("") }} className={`organ ${organ === singleOrgan["organ_id"] ? "active" : ""}`} style={{ width: 100 }}>
            <img src={organ === singleOrgan["organ_id"] || hoverOrgan === singleOrgan["organ_id"] ? data["list"][i]["organ_img"] : data["list"][i]["organ_shade"]} alt="" />
        </div>
    ))

    return (
        <div className="organs-base container-wrapper">
            <div className="human-body-section">
                <div className="human-body-image-wrapper">
                    {
                        data && data["list"] &&
                        <img className="human-image" src={data["list"].find(o => o["organ_id"] === organ)["body_img"]} alt="" />
                    }

                </div>
            </div>
            <div className="organs-section">
                {
                    data && data["list"] &&
                    <div>

                        <div className="organs-list">
                            <Slider {...settings}>
                                {slides}
                            </Slider>
                        </div>
                    </div>
                }
                <h1 className='title'>{data && data["list"].find(o => o["organ_id"] === organ)["title"]}</h1>
                {data && data.stories && <div className="article-list">
                    {data && data.stories && data.stories[organ] ? data.stories[organ].slice(0, 6).map((item, i) => <StoryCard key={i} collection={item} />) : <h1>No Articles Found</h1>}
                </div>}
            </div>
            <div className="recent-section">
                <h1>சமீபத்திய கட்டுரைகள்</h1>
                {recents && recents["latest-article"] && <div className="recent-list">
                    {recents["latest-article"] && recents["latest-article"].slice(0, 4).map(item => <RecentStoryCard key={item["article_id"]} collection={item} />)}
                </div>
                }
            </div>
        </div>
    );
}