import {GET_RECENTS} from '../types'

const initialState = {
    recents:[],
    loading:true
}

const recentsReducer = (state = initialState, action) => {
    
    switch(action.type){
        case GET_RECENTS:
        return {
            ...state,
            recents:action.payload,
            loading:false
        }
        default: return state
    }

}

export default recentsReducer;